/** @jsx jsx */
import { jsx, Styled, Grid, useThemeUI, ThemeProvider, Heading } from 'theme-ui';
import { Layout, SEO } from 'gatsby-theme-catalyst-core';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { FaRegClock } from 'react-icons/fa';
import { useCatalystBlogConfig } from 'gatsby-theme-catalyst-blog';
import { useLithiumConfig } from 'gatsby-theme-catalyst-lithium';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Hero from './hero';

const PostBreakPoinst = ({ children }) => {
	const { theme } = useThemeUI();
	return (
		<ThemeProvider
			theme={{
				...theme,
				breakpoints: [ '390px', '653px', '768px', '1024px', '1440px' ]
			}}
		>
			{children}
		</ThemeProvider>
	);
};

const Quoat = (props) => {
	// .quote {
	// 	font-size: 2em;
	// 	font-family: Times New Roman, times, serif;
	// 	position: relative;
	// }
	// .quote p {
	// 	margin: auto;
	// 	text-align: justify;
	// }
	// .quote span {
	// 	font-size: 4em;
	// }
	// .quote span.open {
	// 	position:absolute;
	// 	top:-0.35em;
	// 	left:0;
	// }
	// .quote span.close {
	// 	position:absolute;
	// 	bottom: -0.8em;
	// 	right: 0;
	// }

	return (
		<p
			sx={{
				//fontSize: '2em',
				//backgroundColor: 'grey',
				position: 'relative',
				m:2,
				p:0,
				px:23,
				display: 'inline-block'
				//textAlign: 'center'
			}}
		>
			<span
				sx={{
					fontFamily: 'Times New Roman, times, serif',
					fontSize: '2em',
					position: 'absolute',
					top: '-0.10em',
					left: 0
				}}
			>
				&ldquo;
			</span>
			{props.children}
			<span
				sx={{
					fontFamily: 'Times New Roman, times, serif',
					bottom: '-0.4em',
					fontSize: '2em',
					position: 'absolute',
					right: 0
				}}
			>
				&rdquo;
			</span>
		</p>
	);
};

const PostsList = ({ posts }) => {
	const { postListTitle, displayPostListTitle } = useCatalystBlogConfig();
	const { useHero } = useLithiumConfig();

	const components = {
		h2: (props) => <Heading sx={{ color: '#57A488', mb: 2 }} {...props} />,
		h3: (props) => <Heading as="h3" sx={{ fontSize: 3 }} {...props} />,
		p: (props) => <Styled.p {...props} />,
		blockquote: (props) => <Quoat {...props} />

		//p: MyParagraph,
	};
	// return (
	// 	<Layout>
	// 		<SEO />
	// 		<Hero />
	// 	</Layout>
	// );
	return (
		<Layout>
			<SEO />
			{useHero && <Hero />}
			<div
				id="methode"
				sx={{
					width: '100vw',
					position: 'relative',
					left: 'calc(-50vw + 50%)',
					my: 5
				}}
			>
				<div
					sx={{
						mx: 'auto',
						width: '100%',
						// maxWidth: 'maxPageWidth',
						maxWidth: [ '450px', null, null, '600px', '700px' ]
					}}
				>
					<PostBreakPoinst>
						<div
							sx={{
								//mx: [ 3, 4, null, 5, null ],
								// display: 'grid',
								// gridTemplateColumns: [ '1fr', null, '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr' ],
								// gridTemplateRows: 'auto',
								// gridGap: 4,
								justifyContent: 'center',
								variant: 'variants.postListContainer'
							}}
						>
							{false ? (
								<Styled.h1
									sx={{
										variant: 'variants.postListPageTitle'
									}}
								>
									{postListTitle}
								</Styled.h1>
							) : null}
							{posts.map((post) => {
								const title = post.title || post.slug;
								return (
									<article
										sx={{
											backgroundColor: 'white',
											textDecoration: 'none',
											color: 'text',
											mb: 3,
											//			boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
											display: 'flex',
											flexDirection: 'column',
											minHeight: '100%',
											position: 'relative',
											top: '0',
											transition: 'all .1s ease-in',
											a: {
												color: 'text',
												textDecoration: 'none'
											},
											// ':hover': {
											// 	top: '-4px',
											// 	boxShadow: '0 4px 5px rgba(0,0,0,0.2)'
											// },
											// ':nth-of-type(1)': {
											// 	gridColumn: [ '1 / -1', null, '1 / span 2', null, null ],
											// 	h2: {
											// 		fontSize: [ 3, null, 5, null, null ]
											// 	},
											// 	'.featuredImage': {
											// 		height: [ '250px', null, '350px', null, null ]
											// 	}
											// },
											variant: 'variants.postListWrapper'
										}}
										key={post.slug}
									>
										{/* <Link to={post.slug}> */}
										<Grid
											sx={{
												gridTemplateColumns: [
													'auto',
													'auto'
													// 'fit-content(400px) 1fr',
													// '1fr 1fr'
												]
											}}
										>
											<div
												sx={{
													p: 3
												}}
											>
												<Styled.p sx={{ color: 'green', fontSize: '18px' }}>
													{post.subTitle}
												</Styled.p>
												{/* <Styled.ul
                        sx={{
                          display: "flex",
                          listStyle: "none",
                          m: 0,
                          p: 0,
                        }}
                      >
                        {post.categories.map((category) => (
                          <Styled.li
                            sx={{
                              textTransform: "uppercase",
                              letterSpacing: "wide",
                              color: "primary",
                              fontSize: 1,
                              fontWeight: "bold",
                              "::after": {
                                content: `"\\2022"`,
                                px: 2,
                              },
                              ":last-of-type": {
                                "::after": {
                                  content: "none",
                                },
                              },
                              variant: "variants.postListCategory",
                            }}
                          >
                            {category}
                          </Styled.li>
                        ))}
                      </Styled.ul> */}
												<Styled.h2
													sx={{
														mt: 1,
														fontSize: 3,
														variant: 'variants.postListTitle',
														color: 'blue'
													}}
												>
													{title}
												</Styled.h2>
												{/* <Styled.p
                        sx={{
                          color: "textGray",
                          fontSize: 1,
                          textTransform: "uppercase",
                          letterSpacing: "wider",
                          m: 0,
                          a: {
                            color: "textGray",
                            textDecoration: "none",
                            ":hover": {
                              textDecoration: "underline",
                            },
                          },
                          variant: "variants.postListMeta",
                        }}
                      >
                        {post.date} &bull;{" "}
                        <FaRegClock
                          sx={{
                            position: "relative",
                            top: "0.125em",
                          }}
                        />{" "}
                        {post.timeToRead} Min
                      </Styled.p> */}
												<Styled.p
													sx={{
														variant: 'variants.postListExcerpt',
														colort: '#4f545d'
													}}
												>
													{/* {post.excerpt} */}
													<MDXProvider components={components}>
														<MDXRenderer>{post.body}</MDXRenderer>
													</MDXProvider>
												</Styled.p>
											</div>
											{/* <Img
													className="featuredImage"
													imgStyle={{ objectFit: 'contain' }}
													sx={{
														gridRow: [ '1' ],
														gridColumn: [ '1', null, '2' ],
														width: '100%',
														height: '100%',
														justifySelf: 'center',
														maxWidth: [ '350px', null, null, '350px' ],
														mb: [ 2, null, 3 ],
														//	ml: [ 3, null, null ],
														variant: 'variants.postListImage'
													}}
													fluid={post.featuredImage.fluid}
													alt={post.title}
												/> */}
										</Grid>
										{/* </Link> */}
									</article>
								);
							})}
						</div>
					</PostBreakPoinst>
				</div>
			</div>
		</Layout>
	);
};

export default PostsList;
